import * as _ from 'lodash';
export class ContractTemplate {
  content = '';
  config = '';
  fields = [];
  constructor(templateObject) {
    this.content = templateObject.content;
    const a =JSON.parse(templateObject.config).map(it => {
      if (it.psnId === '[甲方名称]') {
        it.signType='1'
      }
      if (it.psnId === '[乙方名称]') {
        it.signType='2'
      }
      return it
    })

    this.config = JSON.stringify(a);

    this.getFieldsFromTemplate();

  }
  getFieldsFromTemplate () {
    // const sort = ["甲方名称", "乙方名称", "丙方名称", "丁方名称", "戊方名称"];
   
    const str = (
      this.content.match(
        /<input\s+type="button"\s+value="\[[A-Za-z0-9_\-\u4e00-\u9fa5]+\]"\s+data-type="[a-zA-Z0-9]+"\s+data-label="[A-Za-z0-9_\-\u4e00-\u9fa5]+"\s*\/>/g
      ) || []
    ).join('');
    const reg = /[a-z\-A-Z]+="[A-Za-z0-9_\-\u4e00-\u9fa5]+"/g;
 
    let result;
    const list = [];
   
    while ((result = reg.exec(str))) {
      if (result) {
        result = result.toString();
        if (/type="button"/.test(result)) {
          list.push({
            value: ''
          });
        } else {
          result = result
            .toString()
            .replace(/data-|"/g, '')
            .split('=');
          list[list.length - 1][result[0]] = result[1];
        }
      }
    }

    this.fields = _.uniqWith(list, _.isEqual).map(it => {
      if (it.type === 'subject') {
     
        if (it.label === '甲方名称') {
          it.value = {
            subjectType: it.label==='甲方名称'?'1':'2',
            psnId: '',
            psnName: '',
            orgId: window.localStorage.getItem('enterpriseEsignAccount'),
            orgName: window.localStorage.getItem('enterpriseName')
          };
        }
        else {
          it.value = {
            subjectType: it.label==='甲方名称'?'1':'2',
            psnId: '',
            psnName: '',
            staffCode:''
          };
        }
      }
      return it;
    });
 
  }
  getReplaceConfig () {
    let str = this.config;
    this.fields.forEach(it => {
      if (it.type === 'subject') {
        const reg = new RegExp(`"psnId":"\\[${it.label}\\]`, 'g');
        const reg1 = new RegExp(`"psnName":"\\[${it.label}\\]`, 'g');
        str = str
          .replace(reg, `"psnId":"${it.value.psnId}`)
          .replace(reg1, `"psnName":"${it.value.psnName}`);
      }
    });

    var s = JSON.parse(str)
    var a = this.fields.filter(it => {
      if (it.type === 'subject'&&it.label==='乙方名称') {
        return it 
      }
    })[0]
    var  str2 = s.map(it => {
        if (it.psnId === a.value.psnId) {
          return {
            staffCode : a.value.staffCode,
            ...it
          }
       }
        else {
          return it
        }
      })
    return JSON.stringify(str2);
  
  }
  getReplaceContent () {
    return this.fields.forEach(it => {
      let str = _.cloneDeep(this.content)
      if (it.type === 'subject') {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        str = str.replace(reg, it.value.psnName); 
      } else {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        str = str.replace(reg, it.value);
      }
      return str
    });
  }
  replaceConfig (fields) {
    let str = _.cloneDeep(this.config)
 
    fields.forEach(it => {
      if (it.type === 'subject') {
        const reg = new RegExp(`"psnId":"\\[${it.label}\\]`, 'g');
        const reg1 = new RegExp(`"psnName":"\\[${it.label}\\]`, 'g');
        str = str
          .replace(reg, `"psnId":"${it.value.psnId}`)
          .replace(reg1, `"psnName":"${it.value.psnName}`);
      }
    });

    var s = JSON.parse(str)
    const map = {}
    fields.forEach(n => map[n.value.psnId] = { ...n.value, ...(map[n.value.psnId] || {}) })
  
  
    var arr = s.map(n => {
      if (n.signType === '1') {
        n['orgId'] = map[n.psnId].orgId
        n['orgName']= map[n.psnId].orgName
      } else {
        n['staffCode']= map[n.psnId].staffCode
      }
      return  n
    })

    return arr;
  }
  getReplaceConfigTwo () {
    const t = []
    const stat = this
    this.fields.forEach((n, i) => {
      if (_.isArray(n.value.psnId)) {
        n.value.psnId.forEach((m, j) => {
          let _fields = _.cloneDeep(this.fields)
          _fields[i].value.psnId = m
          _fields[i].value.psnName = n.value.psnName[j]
          _fields[i].value.staffCode = n.value.staffCode[j]
         
          t.push(_fields)
        })
      }
    })
    if (t.length > 1) {
      return t.map(n => stat.replaceConfig(n))
    } else {
      return stat.replaceConfig(stat.fields)
    }
  }
  getReplaceContract () {
    const t = []
    const stat = this
   
    this.fields.forEach((n, i) => {
      if (_.isArray(n.value.psnId)) {
        n.value.psnId.forEach((m, j) => {
          let _fields = _.cloneDeep(this.fields)
          _fields[i].value.psnId = m
          _fields[i].value.psnName = n.value.psnName[j]
          t.push(_fields)
        })
      }
    })

    if (t.length > 1) {
      return t.map(n => stat.replaceOne(n))
    } else {
      return [stat.replaceOne(stat.fields)]
    }
  }

  replaceOne (fields) {
    let str = _.cloneDeep(this.content)

    fields.forEach(it => {
      if (it.type === 'subject') {
  
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
    
        // str = str.replace(reg, it.value.psnName);
        if (it.label==='甲方名称') {
          str = str.replace(reg, it.value.orgName);
        }
        else {
          str = str.replace(reg, it.value.psnName);
        }
      
      } else {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        str = str.replace(reg, it.value);
      }
    })
  
    return str
  }
  getSubjectFields () {
    const list = this.fields.filter(it => it.type === 'subject');

    return {
      subjectTypes: list.map(it => it.value.subjectType).join(','),
      subjectAccounts: list.map(it => it.value.psnId).join(',')
    };
  
  }
}
